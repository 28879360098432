<!--
 * @Description:首页 停车订单 parkingOrder new
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-29 00:34:29
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainbody'>
    <van-nav-bar title="停车订单"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <!-- 顶部内容 -->
    <div class="top">
      <div class="top-left"
           @click="handleClickPlateNumber">
        <span v-if="searchForm.plateNumber===''">请输入车牌</span>
        <span v-else>{{searchForm.plateNumber}}</span>
      </div>
      <div class="top-line"></div>
      <div class="top-middle"
           @click="handleClickChooseTime">
        <span v-if="searchForm.orderCreateStartTime===''">请选择时间</span>
        <span v-else>{{searchForm.orderCreateStartTime.split(' ')[0]}}</span>
      </div>
      <div class="top-line"></div>
      <div class="top-right"
           @click="handleClcikOrderStatus">
        <span v-if="searchForm.orderStatusCode.length===0||searchForm.orderStatusCode.length===3">
          全部订单</span>
        <span v-else>
          <span v-if="searchForm.orderStatusCode.includes(-1)">
            欠费
          </span>
          <span v-if="searchForm.orderStatusCode.includes(1)">
            未支付
          </span>
          <span v-if="searchForm.orderStatusCode.includes(2)">已支付</span>
        </span>
      </div>
    </div>
    <!-- 车牌号及状态 -->
    <div class="content">
      <van-list v-model="loading"
                :finished="finished"
                finished-text="没有更多了!"
                :error.sync="error"
                error-text="请求失败，点击重新加载"
                @load="queryParkOrder">
        <div class="content-item"
             v-for="item  in orderList"
             :key="item.orderSequence">
          <div class="content-item-title">
            <div class="line"></div>
            <div class="plateNumber">{{item.plateNumber}}</div>
            <div class="details"
                 @click="handleClcikDetails(item)">详情</div>
          </div>
          <div class="content-item-content">
            <div class="plateColor">
              <span class="content-title">车牌颜色:</span>
              <span class="content-content">{{item.plateColor}}</span>
            </div>
            <div class="entranceTime">
              <span class="content-title">进场时间:</span>
              <span class="content-content">{{item.entranceTime}}</span>
            </div>
            <div class="orderStatus">
              <span class="content-title">订单状态:</span>
              <span :class="Number(item.orderStatusCode)>0?(item.orderStatusCode===1?'needpay':'payed'):'lack'">{{item.orderStatus}}</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <!-- 车牌号输入 -->
    <van-popup v-model="plateNumberPopUp"
               class="plateNumberPopClass"
               position="bottom"
               round
               @closed=plateNumberPopClose>
      <div class="content">
        <div class="content-title">
          <div class="line"></div>
          <div class="title-content">请输入车牌</div>
        </div>
        <!-- 车牌组件 -->
        <div class="plateInputDiv">
          <plate-number-input class="plateNumberInput"
                              ref="numInput"
                              v-model="plateNumber"
                              @idxChange='handleClickChangeIndex'
                              @currentPlateNumber='handleShowPlateNumber' />
        </div>
        <div class="content-title">
          <div class="line"></div>
          <div class="title-content">车牌颜色</div>
        </div>
        <van-row class="plateColorDiv">
          <van-row class="plateColorItem"
                   v-for="(item,index) in plateColorList"
                   :key="index"
                   :style="item.code==numberPlateColorCode?`border:2px solid ${colorJson[item.name]}`:''"
                   @click="handleSelect(item)">
            <van-row class="itemLeft"
                     :style="{'background':colorJson[item.name]}"></van-row>
            <span style="margin-left:5px">{{item.name}}</span>
          </van-row>
        </van-row>
        <!-- 车牌组件 -->
        <keyborad-button class="keyboradButton"
                         ref="keyboard"
                         @handleClickRemoveAll="handleClickRemoveAll"
                         @click="handleKeyboardClick" />
        <div class="content-button">
          <van-button class="content-button-item"
                      :disabled=buttonDisable
                      @click="handleClickConfirm">确认</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 时间选择 -->
    <van-popup v-model="timePopUp"
               @closed=plateNumberPopClose
               position="bottom"
               round
               :style="{ height: '30%' }">
      <van-datetime-picker @confirm="timeConfirm"
                           @cancel="timeCancle"
                           v-model="currentDate"
                           :formatter="formatter"
                           type="date"
                           title="选择时间" />
    </van-popup>
    <!-- 订单类型 -->
    <van-popup v-model="orderStatusPopUp"
               round
               position="bottom"
               class="orderStatusPopUpClass">
      <van-row class="content">
        <van-checkbox-group ref="checkboxGroup"
                            v-model="checkboxGroupResult"
                            @change="checkboxGroupChange">
          <van-row class="content-item"
                   v-for="(item,index) in orderStatusList"
                   :key=index>
            <van-checkbox class="item-checkbox"
                          icon-size="17px"
                          :name="item">
              <van-row class="item-content"> 订单状态 ：{{item.orderStatus}}</van-row>
            </van-checkbox>
          </van-row>
        </van-checkbox-group>
        <van-row class="content-item">
          <van-checkbox v-model="selectAllOrNotAll"
                        class="item-checkbox"
                        icon-size="17px"
                        @click="changeAll(selectAllOrNotAll)">
            <van-row class="item-content">{{selectAllOrNotAll? '全不选':'全选'}}</van-row>
          </van-checkbox>

        </van-row>
        <div class="content-button">
          <van-button class="content-button-item"
                      @click="handleClickOrderStatusConfirm">确认</van-button>
        </div>
      </van-row>
    </van-popup>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import plateNumberInput from '@/components/plateNumberNew/plateNumberInput'
import keyboradButton from '@/components/plateNumberNew/keyboradButton'
import formatDate from '@/utils/fmtDate'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: { plateNumberInput, keyboradButton },
  data () {
    // 这里存放数据
    return {
      loading: false,
      finished: false,
      error: false,
      // 查询条件
      searchForm: {
        parkId: this.$cookie.get('managePark').manageParkId,
        plateNumber: '',
        orderStatusCode: [],
        orderCreateStartTime: '',
        numberPlateColorCode: '',
        pageNum: 0,
        pageSize: 10
      },
      orderList: [], // 订单列表
      plateNumberPopUp: false, // 车牌弹窗
      plateColorList: [], // 车牌颜色
      // 车牌颜色
      colorJson: {
        蓝色: '#5A6DF4',
        绿色: '#009017',
        黑色: '#262628',
        黄色: '#F6C210',
        白色: '#C8CAD8'
      },
      plateNumber: '', // 车牌号
      plateColor: '蓝色', // 车牌颜色
      numberPlateColorCode: '2', // 默认蓝色车牌编码
      buttonDisable: true, // 确认按钮
      timePopUp: false, // 时间选择弹窗
      currentDate: new Date(),
      orderStatusPopUp: false, // 订单状态弹窗
      checkboxGroupResult: [], // 订单状态选择结果
      selectAllOrNotAll: '', // 是否全选
      orderStatusList: [
        { orderStatusCode: -1, orderStatus: '欠费' },
        { orderStatusCode: 1, orderStatus: '未支付' },
        { orderStatusCode: 2, orderStatus: '已支付' }
      ]
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    // 监听车牌长度变化
    'searchForm.plateNumber' () {
      if (this.searchForm.plateNumber.length < 7) {
        this.buttonDisable = true
        this.numberPlateColorCode = '2'
        this.searchForm.numberPlateColorCode = '2'
      }
      if (this.searchForm.plateNumber.length >= 7) {
        this.buttonDisable = false
      }
      if (this.searchForm.plateNumber.length === 8) {
        this.plateColor = '绿色'
        this.numberPlateColorCode = '6'
        this.searchForm.numberPlateColorCode = '6'
      }
      if (this.searchForm.plateNumber.length !== 8) {
        this.plateColor = '蓝色'
        this.numberPlateColorCode = '2'
        this.searchForm.numberPlateColorCode = '2'
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getPlateColor()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 查询订单 type = 1 累加列表数据 type = 2 覆盖列表数据
    queryParkOrder (type = 1) {
      this.error = false
      if (type === 1) {
        this.searchForm.pageNum++
      } else {
        this.searchForm.pageNum = 1
      }
      if (this.searchForm.plateNumber === '' &&
        this.searchForm.orderCreateStartTime === '' &&
        (this.searchForm.orderStatusCode.length === 0 ||
          this.searchForm.orderStatusCode.length === 3)
      ) {
        this.searchForm.parkLayerId = this.$cookie.get('manageParkLayer').manageParkLayerId
      } else {
        this.searchForm.parkLayerId = ''
      }
      this.$parkingOrder.getOrderByOrderStatus(this.searchForm).then(res => {
        this.loading = false
        if (type === 1) {
          this.orderList = [...this.orderList, ...res.resultEntity.content]
        } else {
          this.orderList = res.resultEntity.content
          this.finished = false
        }
        if (this.orderList.length >= res.resultEntity.totalElements) {
          this.finished = true
        }
      }).catch(() => {
        this.error = true
        this.loading = false
        if (type !== 1) {
          this.orderList = []
        }
      })
    },
    // 点击详情
    handleClcikDetails (item) {
      const info = {
        orderSequence: item.orderSequence
      }
      this.$parkingOrder.charge(info).then(res => {
        item = res.resultEntity
        this.queryOrderStatus(item) // 查看信用状态
      })
    },
    // 点击详情前先查询订单状态
    queryOrderStatus (item) {
      const info = {
        plateNumber: item.plateNumber,
        numberPlateColorCode: item.numberPlateColorCode
      }
      this.$parkingOrder.getArrearsOrderInfoByPlateNumberAndCode(info).then(res => {
        // 车辆无欠费订单
        if (res.resultEntity.order.length === 0) {
          item.xinyongText = '该车信用良好无欠费'
          item.xinyongCode = 1
          item.xinyongLength = 0
        } else {
          if (item.orderStatusCode === 2 && res.resultEntity.order.length >= 1) {
            // 已交费车辆有欠费记录
            item.xinyongText = '欠费车辆,请追缴停车费'
            item.xinyongCode = -1
            item.xinyongLength = 1
          } else if (item.orderStatusCode === 1 && res.resultEntity.order.length >= 1) {
            // 未交费车辆有欠费记录
            item.xinyongText = '欠费车辆,请追缴停车费'
            item.xinyongCode = -1
            item.xinyongLength = 1
          } else if (item.orderStatusCode === -1 && res.resultEntity.order.length > 1) {
            // 欠费车辆有多条欠费记录
            item.xinyongText = '欠费车辆,请追缴停车费'
            item.xinyongCode = -1
            item.xinyongLength = 1
          } else if (item.orderStatusCode === -1 && res.resultEntity.order.length === 1) {
            // 欠费车辆有一条欠费记录
            item.xinyongText = '欠费车辆,请缴纳停车费'
            item.xinyongCode = -1
            item.xinyongLength = 0
          }
        }
        this.$router.push({
          name: 'parkOrderDetails',
          query: { orderItem: JSON.stringify(item) }
        })
      })
    },
    // 点击订单状态
    handleClcikOrderStatus () {
      this.orderStatusPopUp = true
    },
    // 订单状态确认
    handleClickOrderStatusConfirm () {
      this.searchForm.numberPlateColorCode = ''
      this.searchForm.orderStatusCode = []
      this.checkboxGroupResult.forEach(item => {
        this.searchForm.orderStatusCode.push(item.orderStatusCode)
      })
      this.orderStatusPopUp = false
      this.queryParkOrder(2)
    },
    // 全选或全不选
    changeAll (state) {
      this.$refs.checkboxGroup.toggleAll(state)
    },
    // 多选组发生改变
    checkboxGroupChange (names) {
      if (names.length < this.orderStatusList.length) {
        this.selectAllOrNotAll = false
      } else {
        this.selectAllOrNotAll = true
      }
    },
    // 点击选择时间
    handleClickChooseTime () {
      this.timePopUp = true
    },
    // 选择时间取消
    timeCancle () {
      this.searchForm.orderCreateStartTime = ''
      this.queryParkOrder(2)
      this.timePopUp = false
    },
    // 选择时间确认
    timeConfirm () {
      this.timePopUp = false
      const currentDateFmt = formatDate(this.currentDate, 'yyyy-MM-dd 00:00:00')
      this.searchForm.orderCreateStartTime = currentDateFmt
      this.searchForm.numberPlateColorCode = ''
    },
    // 时间弹窗内时间格式化
    formatter (type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      } else if (type === 'day') {
        return `${val}日`
      }
      return val
    },
    // 点击输入车牌
    handleClickPlateNumber () {
      this.plateNumberPopUp = true
    },
    // 车牌关闭
    plateNumberPopClose () {
      this.queryParkOrder(2)
    },
    // 确认车牌号
    handleClickConfirm () {
      this.plateNumberPopUp = false
    },
    // 清空车牌
    handleClickRemoveAll () {
      this.$refs.numInput.handleClickRemovePlateNumber()
      this.searchForm.plateNumber = ''
      this.searchForm.numberPlateColorCode = ''
      this.plateColor = '蓝色'
      this.numberPlateColorCode = '2'
    },
    // 选取车牌颜色
    handleSelect (item) {
      if (this.searchForm.plateNumber.length === 8) {
        this.plateColor = '绿色'
        this.numberPlateColorCode = '6'
        this.searchForm.numberPlateColorCode = '6'
      } else {
        this.plateColor = item.name
        this.numberPlateColorCode = item.code
        this.searchForm.numberPlateColorCode = item.code
      }
    },
    // 获取到车牌的值
    handleShowPlateNumber (val) {
      this.searchForm.plateNumber = val
    },
    // 获取车牌输入框的 index
    handleClickChangeIndex (val) {
      this.$refs.keyboard.handleTypeChange(val)
    },
    // 键盘点击事件
    handleKeyboardClick (value) {
      this.$refs.numInput.setInput(value) // 将点击的值传入父组件
    },
    // 获取车牌颜色
    getPlateColor () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2'
          }
        ]
      }
      this.$register.queryPlateColor(info).then((response) => {
        this.plateColorList = response.resultEntity.filter(item => {
          if (item.code !== '4' && item.code !== '5') {
            return item
          }
        })
      })
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.push({ name: 'homePage' })
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainbody {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .top {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(221, 221, 221, 0.16);
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    .top-line {
      width: 1px;
      height: 100%;
      background: #f1f3f7;
    }
    .top-left {
      width: 33%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .top-middle {
      width: 33%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .top-right {
      width: 33%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 86px - 48px);
    overflow-x: hidden;
    overflow-y: auto;
    .content-item {
      width: 92%;
      height: 140px;
      margin-top: 10px;
      margin-left: 4%;
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(221, 221, 221, 0.16);
      opacity: 1;
      border-radius: 4px;
      .content-item-title {
        width: 100%;
        height: 39px;
        border-bottom: 1px dashed #dddddd;
        display: flex;
        align-items: center;
        position: relative;
        .line {
          width: 2px;
          height: 18px;
          background: #0aa0f4;
          opacity: 1;
          border-radius: 6px;
          margin-left: 3%;
        }
        .plateNumber {
          width: 50%;
          margin-left: 2%;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 0px;
          color: #333333;
          opacity: 1;
        }
        .details {
          width: 48px;
          height: 22px;
          border-radius: 11px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          background: #19a9fc;
          color: #ffffff;
          text-align: center;
          position: absolute;
          right: 3%;
        }
      }
      .content-item-content {
        width: calc(100% - 5% -2px);
        height: 100px;
        padding-left: calc(5% + 2px);
        .content-title {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 0px;
          color: #666666;
          opacity: 1;
        }
        .content-content {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 0px;
          color: #333333;
          opacity: 1;
          margin-left: 10px;
        }
        .plateColor {
          height: 33px;
          line-height: 33px;
        }
        .entranceTime {
          height: 33px;
          line-height: 33px;
        }
        .orderStatus {
          height: 33px;
          line-height: 33px;
          .lack {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 0px;
            color: #ff6c76;
            opacity: 1;
            margin-left: 10px;
          }
          .needpay {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 0px;
            color: #ff6c76;
            opacity: 1;
            margin-left: 10px;
          }
          .payed {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 0px;
            color: #19a9fc;
            opacity: 1;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .plateNumberPopClass {
    .content {
      width: calc(100% - 16px);
      margin: 10px 8px;
      background: #ffffff;
      box-shadow: 0px 3px 10px rgba(157, 224, 255, 0.08);
      border-radius: 20px;
      .content-title {
        width: calc(100% - 32px);
        padding: 8px 16px;
        display: flex;
        .line {
          width: 4px;
          height: 22px;
          background: #559efc;
          opacity: 1;
          border-radius: 3px;
        }
        .title-content {
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          margin-left: 8px;
        }
      }
      .plateColorDiv {
        width: 100%;
        height: 30px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-left: 1%;
        .plateColorItem {
          width: 17.5%;
          height: 30px;
          line-height: 30px;
          text-align: center;
          margin-left: 1%;
          font-family: PingFangSC-Regular;
          font-size: 13px;
          color: #333333;
          letter-spacing: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid #dbdbdb;
        }
        .itemLeft {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
      .plateInputDiv {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        .plateNumberInput {
          padding-top: 15px;
          background: #ffffff;
        }
      }
      .content-button {
        width: 100%;
        height: 44px;
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        .content-button-item {
          width: 56%;
          background: linear-gradient(130deg, #43b7fa 0%, #009bf3 100%);
          border-radius: 4px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
          border: none;
        }
      }
    }
    .keyboradButton {
      width: 100%;
      height: 250px;
      border-radius: 15px;
      background: #e2e3e7;
      margin-top: 20px;
    }
  }
  .orderStatusPopUpClass {
    .content {
      width: 100%;
      height: calc(100% - 70px - 46px);
      margin-top: 10px;
      overflow-x: hidden;
      overflow-y: auto;
      .content-item {
        width: 100%;
        height: 70px;
        background-color: #ffffff;
        border-bottom: 1px solid #e7e8e8;
        display: flex;
        align-items: center;
        .item-checkbox {
          width: 100%;
          display: flex;
          align-items: center;
          margin-left: 30px;
          .item-content {
            width: 100%;
            margin-left: 20px;
          }
        }
      }
      .content-button {
        width: 100%;
        height: 44px;
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        .content-button-item {
          width: 56%;
          background: linear-gradient(130deg, #43b7fa 0%, #009bf3 100%);
          border-radius: 4px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
          border: none;
          bottom: 5px;
        }
      }
    }
  }
}
</style>
